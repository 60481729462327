var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-media-block"},[(_vm.candidate.medias.some(function (x) { return x.mediaType === 2; }))?_c('b-container',{staticClass:"vid-container"},[(
        _vm.candidate.medias.filter(
          function (x) { return x.mediaType === 2 && x.mediaAction === 1; }
        ).length > 1
      )?_c('b-carousel',{staticStyle:{"text-shadow":"0px px 2px #333"},attrs:{"id":"carousel-1","interval":0,"controls":"","indicators":"","background":"#ababab","img-width":"1024","img-height":"480"},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.videoList),function(video,i){return _c('b-carousel-slide',{key:i,attrs:{"id":"video-wrapper"}},[_c('div',{staticClass:"carousel-slide-item"},[(video.path && video.path.includes('tiktok'))?_c('div',[_c('iframe',{staticClass:"embed-responsive-item",staticStyle:{"width":"100%","min-height":"730px"},attrs:{"src":_vm.ExtractTiktokFromPath(video.path),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):(video.path && video.path.includes('youtube.com/shorts'))?_c('div',[_c('iframe',{staticClass:"embed-responsive-item",staticStyle:{"width":"100%","min-height":"730px"},attrs:{"src":_vm.ExtractYoutubeShortsFromPath(video.path),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):(video.path && video.path.includes('instagram'))?_c('ig-block',{attrs:{"src":video.path}}):_c('div',{staticClass:"embed-responsive embed-responsive-4by3"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.ForceEmbedVideo(video.path),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),(video.caption == "null" ? "" : video.caption)?_c('b-card-text',[_vm._v(" "+_vm._s(video.caption == "null" ? "" : video.caption)+" ")]):_vm._e()],1)])}),1):_vm._l((_vm.candidate.medias.filter(
        function (x) { return x.mediaType === 2 && x.mediaAction === 1; }
      )),function(video,i){return _c('b-card',{key:i,staticClass:"video-block"},[_c('div',{staticClass:"embed-responsive embed-responsive-4by3"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.ForceEmbedVideo(video.path),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),(video.caption == "null" ? "" : video.caption)?_c('b-card-text',[_vm._v(" "+_vm._s(video.caption == "null" ? "" : video.caption)+" ")]):_vm._e()],1)})],2):_vm._e(),(_vm.candidate.medias.some(function (x) { return x.mediaType === 1; }))?_c('b-container',{staticClass:"img-container"},[_c('div',[_c('b-carousel',{directives:[{name:"show",rawName:"v-show",value:(_vm.candidate.medias.length > 0),expression:"candidate.medias.length > 0"}],staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-1","interval":8500,"controls":_vm.showControls,"indicators":_vm.showControls,"background":"#ababab","img-width":"1024","img-height":"550"}},_vm._l((_vm.candidate.medias.filter(
            function (x) { return x.mediaType === 1 && x.mediaAction === 1; }
          )),function(slide,i){return _c('b-carousel-slide',{key:i,attrs:{"text":slide.caption == "null" ? "" : slide.caption},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"d-block img-fluid w-100",attrs:{"width":"1024","height":"550","src":slide.path,"alt":"image slot"}})]},proxy:true}],null,true)})}),1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }