<template>
  <div class="template-media-block">
    <b-container
      v-if="candidate.medias.some((x) => x.mediaType === 2)"
      class="vid-container"
    >
      <b-carousel
        v-if="
          candidate.medias.filter(
            (x) => x.mediaType === 2 && x.mediaAction === 1
          ).length > 1
        "
        id="carousel-1"
        v-model="slide"
        :interval="0"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 0px px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="(video, i) in videoList"
          :key="i"
          id="video-wrapper"
        >
          <div class="carousel-slide-item">
            <div v-if="video.path && video.path.includes('tiktok')">
              <iframe
                class="embed-responsive-item"
                :src="ExtractTiktokFromPath(video.path)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style="width: 100%; min-height: 730px"
              ></iframe>
            </div>
            <div v-else-if="video.path && video.path.includes('youtube.com/shorts')">
              <iframe
                class="embed-responsive-item"
                :src="ExtractYoutubeShortsFromPath(video.path)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style="width: 100%; min-height: 730px"
              ></iframe>
            </div>
            <!-- <tiktok-wrapper v-if="video.path && video.path.includes('tiktok')" :tiktokBlock="video.extras.detail.html"></tiktok-wrapper> -->

            <ig-block
              v-else-if="video.path && video.path.includes('instagram')"
              :src="video.path"
            ></ig-block>

            <div v-else class="embed-responsive embed-responsive-4by3">
              <iframe
                class="embed-responsive-item"
                :src="ForceEmbedVideo(video.path)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <b-card-text v-if="video.caption == `null` ? `` : video.caption">
              {{ video.caption == `null` ? `` : video.caption }}
            </b-card-text>
          </div>
        </b-carousel-slide>
      </b-carousel>

      <b-card
        v-else
        class="video-block"
        v-for="(video, i) in candidate.medias.filter(
          (x) => x.mediaType === 2 && x.mediaAction === 1
        )"
        :key="i"
      >
        <div class="embed-responsive embed-responsive-4by3">
          <iframe
            class="embed-responsive-item"
            :src="ForceEmbedVideo(video.path)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <b-card-text v-if="video.caption == `null` ? `` : video.caption">
          {{ video.caption == `null` ? `` : video.caption }}
        </b-card-text>
      </b-card>
    </b-container>
    <b-container
      v-if="candidate.medias.some((x) => x.mediaType === 1)"
      class="img-container"
    >
      <div>
        <b-carousel
          id="carousel-1"
          :interval="8500"
          :controls="showControls"
          :indicators="showControls"
          background="#ababab"
          img-width="1024"
          img-height="550"
          style="text-shadow: 1px 1px 2px #333"
          v-show="candidate.medias.length > 0"
        >
          <b-carousel-slide
            v-for="(slide, i) in candidate.medias.filter(
              (x) => x.mediaType === 1 && x.mediaAction === 1
            )"
            :key="i"
            :text="slide.caption == `null` ? `` : slide.caption"
          >
            <template #img>
              <img
                class="d-block img-fluid w-100"
                width="1024"
                height="550"
                :src="slide.path"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  ForceEmbedVideo,
  DynamicLoadScript,
  ExtractTiktokFromPath,
  ExtractYoutubeShortsFromPath
} from "@/services/helpers";
import IgBlock from "@/components/IgBlock";
import TiktokWrapper from "@/components/TiktokWrapper";
export default {
  data() {
    return {
      title: "Media Block",
      showControls: true,
      slide: 0,
      sliding: null,
      prevIframeSrc: "",
      prevIframeIndex: 0,
      hasBeenSliding: false,
      videos: [],
      videoList: [],
    };
  },
  components: {
    IgBlock,
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
  },
  created() {
    this.hasMultiplePictures();
    this.videoList = this.candidate.medias.filter(
      (x) => x.mediaType === 2 && x.mediaAction === 1
    );
  },
  methods: {
    ForceEmbedVideo: ForceEmbedVideo,
    ExtractTiktokFromPath: ExtractTiktokFromPath,
    ExtractYoutubeShortsFromPath: ExtractYoutubeShortsFromPath,
    hasCaption(caption) {
      if (caption) {
        return caption;
      } else {
        return false;
      }
    },
    hasMultiplePictures() {
      var pictureCount = this.candidate.medias.filter(function (item) {
        return item.mediaType === 1;
      }).length;
      if (pictureCount > 1) {
        this.showControls = true;
      } else {
        this.showControls = false;
      }
    },
    onSlideStart(slide) {
      this.hasBeenSliding = true;
      this.sliding = true;
      const vidMedia = this.candidate.medias.filter(
        (x) => x.mediaType === 2 && x.mediaAction === 1
      );

      try {
        if (this.hasBeenSliding) {
          const carouselItems = document.querySelectorAll(
            ".carousel-slide-item"
          );

          let prevFrameSrc = "";
          let prevItem = "";

          if (carouselItems[this.slide].querySelector("blockquote")) {
            prevItem = carouselItems[this.slide].querySelector("blockquote");
            prevItem = prevItem.querySelector("iframe");
            prevFrameSrc = prevItem.src;
          } else {
            prevItem = carouselItems[this.slide].querySelector("iframe");
            prevFrameSrc = prevItem.src;
          }

          prevItem.src = "";
          prevItem.src = prevFrameSrc;

          // console.log(carouselItems[this.slide]);
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
  mounted() {
    let mediaBlock = document.querySelector(".template-media-block");
    let mediaBlockContainer = document.querySelector(
      ".template-media-block .container"
    );

    if (!mediaBlockContainer) {
      mediaBlock.classList.add("d-none");
    }

    if (document.querySelector(".carousel")) {
      let carouselChild =
        document.querySelector(".carousel-inner").children.length;
      let carouselAnchors = document.querySelectorAll(".carousel a");
      let carouselIndicators = document.querySelector(".carousel-indicators");

      if (carouselChild < 2) {
        carouselIndicators.classList.add("d-none");

        for (let i = 0; i < carouselAnchors.length; i++) {
          carouselAnchors[i].classList.add("d-none");
        }
      }
    }
  },
  updated() {
    DynamicLoadScript("https://www.tiktok.com/embed.js");
    DynamicLoadScript("https://www.instagram.com/embed.js");
    //Reprocess instagram so it will load dynamically
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }

    // document.getElementById('video-wrapper').contentDocument.location.reload()

    this.videos = this.candidate.medias.filter(
      (x) => x.mediaType === 2 && x.mediaAction === 1
    );
  },
};
</script>

<style lang="scss" scoped>
.carousel-item.active {
  position: relative;
}

::v-deep .carousel-item .carousel-caption {
  position: relative !important;
}

::v-deep .carousel-slide-item iframe {
  display: inline !important;
}

::v-deep blockquote.tiktok-embed {
  border: 0;
}
</style>
